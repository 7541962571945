// Origin: https://vuejs.org/guide/reusability/composables.html#mouse-tracker-example

// event.js
import {onBeforeUnmount, onMounted, onUnmounted, unref} from 'vue'

export function useEventListener(
  target: EventTarget | ref,
  event: string,
  callback: EventListener,
  options: EventListenerOptions | null = null
) {
  // if you want, you can also make this
  // support selector strings as target
  onMounted(() => unref(target).addEventListener(event, callback, options))
  onBeforeUnmount(() => unref(target).removeEventListener(event, callback, options))
}
