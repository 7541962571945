import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { applicationRoutes } from "@/router/application";
import { authRoutes } from "@/router/auth";
import { storeToRefs } from "pinia";
import { useProfileStore } from "@/store/profile.store";
import { toRefs } from "vue";
import useProfile from "@dorm-frontend/common/composables/profile/useProfile";
import { AUTH_LOGIN_VIEW } from "@/constants";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@dorm-frontend/common/views/HomeView.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    children: authRoutes,
  },
  {
    path: "/applications",
    name: "application",
    children: applicationRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const profile = useProfile();

router.beforeEach(async (to, from) => {
  if (profile.status.value === "idle") {
    try {
      await profile.fetchProfile();
    } catch (e: any) {
      console.log(e);
      alert(e.message);
    }
  }
  if (!profile.user.value && !to.path.startsWith("/auth")) {
    return { name: AUTH_LOGIN_VIEW };
  }
});

export default router;
