import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "aside-panel" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_KeepAlive, null, [
      ($setup.logoSrc)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.route.path === '/' ? 'div' : 'router-link'), {
            key: 0,
            to: "/"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                height: "60",
                src: $setup.logoSrc
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 1024)),
    _createVNode($setup["HeaderProfile"]),
    _createElementVNode("nav", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (navItem, i) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "anchor h3-sb-18",
          selected: $setup.route.path.startsWith(navItem.path) || null,
          to: navItem.path
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(navItem.label), 1)
          ]),
          _: 2
        }, 1032, ["selected", "to"]))
      }), 256))
    ])
  ]))
}