import { APPLICATION, TYPES } from "@dorm-frontend/common/constants/magicStrings";

export const locales = {
  ru: {
    [APPLICATION]: {
      [TYPES]: {
        electrician: "электрик",
        carpenter: "плотник",
        "exterminator:cockroach": "тараканы",
        "exterminator:bedbugs": "клопы",
      },
    },
  },
};
