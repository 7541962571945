import { locales } from "../locales";
import { ref } from "vue";
import { LanguageCode } from "../types/locales";

const locale = ref<any>(null);

export default function useLocale() {
  function setLocale(languageCode: LanguageCode) {
    locale.value = locales[languageCode];
  }

  return {
    locale,
    setLocale,
  };
}
