import { RouteRecordRaw } from "vue-router";
import AuthView from "@/views/AuthView.vue";
import SignupForm from "@/components/auth/forms/SignupForm.vue";
import CredentialsForm from "@/components/auth/forms/signup/CredentialsForm.vue";
import UsernameForm from "@/components/auth/forms/signup/UsernameForm.vue";
import {
  AUTH_SIGNUP_CREDENTIALS_VIEW,
  AUTH_SIGNUP_PASSWORD_VIEW,
  AUTH_SIGNUP_USERNAME_VIEW,
} from "@/constants";

export const authSignupRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: AUTH_SIGNUP_CREDENTIALS_VIEW,
    props: {
      authForm: SignupForm,
      stageForm: CredentialsForm,
    },
    component: AuthView,
  },
  {
    path: "username",
    name: AUTH_SIGNUP_USERNAME_VIEW,
    props: {
      authForm: SignupForm,
      stageForm: UsernameForm,
      // authForm: SignupForm,
      // stageForm: CredentialsStage,
    },
    component: AuthView,
  },
  {
    path: "password",
    name: AUTH_SIGNUP_PASSWORD_VIEW,
    props: {
      // authForm: SignupForm,
      // stageForm: CredentialsStage,
    },
    component: AuthView,
  },
];
