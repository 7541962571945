import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["status"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "reloadable-image",
    status: $setup.status,
    onClick: $setup.handleClick
  }, [
    _createElementVNode("i", {
      class: "refresh",
      innerHTML: $setup.refreshIcon
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      src: $setup.imageObjectURL || ''
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}