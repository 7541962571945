import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "image-viewer",
  ref: "rootElement"
}
const _hoisted_2 = { class: "viewer-nav" }
const _hoisted_3 = ["onClick", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.visible)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "viewer-content",
            onClick: $setup.clickHandler
          }, [
            ($setup.selectedImage)
              ? (_openBlock(), _createBlock($setup["CorsImage"], {
                  ref: "mainImage",
                  key: "selectedImage.id",
                  alt: $setup.selectedImage.originalName,
                  src: $setup.API_URL + '/files/' + $setup.selectedImage.id
                }, null, 8, ["alt", "src"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, (image) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "nav-item",
                onClick: ($event: any) => ($setup.selectedImage = image),
                selected: image === $setup.selectedImage || null
              }, [
                _createVNode($setup["ReloadableImage"], {
                  alt: image.originalName,
                  src: $setup.API_URL + '/files/' + image.id
                }, null, 8, ["alt", "src"])
              ], 8, _hoisted_3))
            }), 256))
          ])
        ], 512)
      ]))
    : _createCommentVNode("", true)
}