import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import useLocale from "@dorm-frontend/common/composables/useLocale";

const navItems = [
  {
    path: "/applications",
    label: "Заявки",
  },
];

const { setLocale } = useLocale();
setLocale("ru");
const pinia = createPinia();
const app = createApp(App);
app.use(router).use(pinia);
app.provide("navItems", navItems);

app.mount("#app");
