import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main-section",
  ref: "mainPanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isWideWidth || $props.activeSlot === 'aside')
      ? _renderSlot(_ctx.$slots, "aside", { key: 0 })
      : _createCommentVNode("", true),
    ($setup.isWideWidth || $props.activeSlot === 'default')
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
  ], 512))
}