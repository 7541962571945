import { RouteRecordRaw } from "vue-router";

export const applicationRoutes: Array<RouteRecordRaw> = [
  {
    path: ":id?",
    name: "application-view",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/ApplicationView.vue"),
  },
  {
    path: "new",
    name: "new-application-view",
    props: { createForm: true },
    component: () => import("@/views/ApplicationView.vue"),
  },
];
