
import {computed, ref} from 'vue';

const selectedIndex = ref(0);
const visible = ref(false);
const images = ref([]);

export default function useImageViewer() {

  const selectedImage = computed({
    get: () => images.value[selectedIndex.value],
    set: (image) => {
      selectedIndex.value = images.value.indexOf(image);
    },
  });

  function setImages(imageArray: never[]) {
    images.value = imageArray;
  }

  const show = () => visible.value = true;
  const hide = () => visible.value = false;

  const next = () => {
    if (selectedIndex.value < images.value.length - 1) {
      selectedIndex.value++;
    }
  }

  const prev = () => {
    if (selectedIndex.value > 0) {
      selectedIndex.value--;
    }
  }

  return {
    setImages,
    selectedImage,
    selectedIndex,
    hide, show,
    visible: computed(() => visible.value),  // Readonly
    prev, next,
    images,
  }
}