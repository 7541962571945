import { RouteRecordRaw } from "vue-router";
import AuthView from "@/views/AuthView.vue";
import LoginForm from "@/components/auth/forms/LoginForm.vue";
import { authSignupRoutes } from "@/router/auth/signup";
import { AUTH_LOGIN_VIEW } from "@/constants";

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "login",
    name: AUTH_LOGIN_VIEW,
    props: {
      authForm: LoginForm,
    },
    component: AuthView,
  },
  {
    path: "signup",
    children: authSignupRoutes,
  },
];
