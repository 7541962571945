import { Ref, ref, watch } from "vue";

export default function useAuthForm(
  credentials: Ref<any>,
  errorDescriptions: any
) {
  const inputRefs = ref<any>({});
  const errorMessage = ref<string | null>(null);

  function handleError(error: any) {
    const status = error.response.status;
    if (status in errorDescriptions) {
      errorMessage.value = errorDescriptions[status];
    } else {
      errorMessage.value = "Ошибка: " + error.message;
    }
    const { message } = error.response.data;
    if (message && Array.isArray(message)) {
      for (const err of message) {
        const [_, inputName, e] = err.match(/(\w+)\s+(.+)/);
        const input = inputRefs.value[inputName];
        if (input) {
          const parentElement = input.parentElement;
          parentElement.setAttribute("error", e);
          input.addEventListener(
            "input",
            function () {
              parentElement.removeAttribute("error");
            },
            { once: true }
          );
        }
      }
    }
  }

  watch(credentials, () => (errorMessage.value = null), { deep: true });

  return {
    credentials,
    inputRefs,
    errorMessage,
    handleError,
  };
}
