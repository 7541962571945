import {computed, ref, unref, isRef, watchEffect, watch} from "vue";

import useAxios from "../useAxios";
const { $axios } = useAxios(process.env.VUE_APP_API_URL);

export default function useCorsImage(url: any) {
  const imageObjectURL = ref<string | null>(null);
  const status = ref<string | null>(null);

  async function updateImageSource() {
    try {
      status.value = 'pending';
      const response = await $axios.get(unref(url), {
        responseType: "blob"
      });
        imageObjectURL.value = URL.createObjectURL(response.data);
        status.value = 'loaded';
    } catch (e) {
      console.error(e);
      status.value = 'error';
    }
  }

  if (isRef(url)) {
    watchEffect(updateImageSource);
  } else {
    updateImageSource();
  }

  return {
    imageObjectURL,
    status,
    updateImageSource
  }
}
