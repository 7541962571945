import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-profile" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "username" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "icon user",
      innerHTML: $setup.userIcon
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString($setup.user.firstName), 1)
    ]),
    _createElementVNode("button", {
      class: "icon logout",
      innerHTML: $setup.logoutIcon,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.logout && $setup.logout(...args)))
    }, null, 8, _hoisted_4)
  ]))
}