import { computed, ref } from "vue";

import useAxios from "@dorm-frontend/common/composables/useAxios";
import { isAxiosError } from "axios";
import {useRouter} from "vue-router";
const { $axios } = useAxios(process.env.VUE_APP_API_URL);

const user = ref(null);
const status = ref("idle");

export default function useProfile() {
  const router = useRouter();

  async function fetchProfile() {
    try {
      status.value = "pending";
      const response = await $axios.get("/profile");
      user.value = response.data;
      status.value = "succeeded";
    } catch (e) {
      if (isAxiosError(e) && e?.response?.status === 401) {
        status.value = "failed";
      } else {
        throw e;
      }
    }
  }

  async function logout() {
    try {
      await $axios.post("/auth/logout");
      await router.push({name: 'auth-login-view'});
    } catch (e) {
      if (isAxiosError(e) && e?.response?.status === 401) {
        status.value = "failed";
      } else {
        throw e;
      }
    }
  }

  return {
    user,
    logout,
    status: computed(() => status.value),
    fetchProfile,
  };
}
