import axios from "axios";

export default function useAxios(baseURL = '') {
  const $axios = axios.create({
    baseURL,
    withCredentials: true,
  });

  return {
    $axios,
  };
}
